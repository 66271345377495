/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import Yourfocus from "../../images/illustrations/aboutus_yourfocus.png"
import styled from "styled-components"

export default class Schedule extends React.Component {
  state = {
    name: "",
    email: "",
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })

    this.setState({
      mailToString:
        "/schedule-demo?name=" + this.state.name + "&email=" + this.state.email,
    })
  }

  render() {
    return (
      <div>
        <div className="relative overflow-hidden">
          <div className="mx-auto p-6 md:pt-12 sm:p-8">
          
            <div className="md:flex p-4 justify-center">
              <div className="ml-8 flex flex-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3 ">
                <img
                  style={{ width: 460 }}
                  className="w-auto h-auto"
                  src={Yourfocus}
                  alt="illustration"
                />
              </div>

              <div className="ml-8 flex flex-col w-full md:w-2/3 lg:w-2/3 xl:w-2/3 ">
                <div className="">
                <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mt-1 para"
                    >
                      Every individual in every industry require COACHING to lead a successful life. Since 2019, we provide enourmous support to individuals, strartups, entreprenuers, students and organizations through coaching. Being Zone coaches hold a beautiful space to bring awareness and unleash the potential of an individual to nurture the mind to be confident, conscious, and consistent.
                    </p>
                </div>
                 <div className="mt-4">
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 20,
                      }}
                      className=" mt-4 uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      TRANSFORMATION AS A WHOLE
                    </h2>

                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mt-1 para"
                    >
                      We aim to work for real and meaningful outcomes through professionally certified expert coaches with one-on-one and group coaching. The transformation reflects in everyday life, whether decision, purpose, relationship, leadership, etc.
                    </p>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}

/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import valueIllust1 from "../../images/illustrations/ourvalues-focus.png"
import valueIllust2 from "../../images/illustrations/ourvalues-accountable.png"
import valueIllust3 from "../../images/illustrations/ourvalues-curious.png"
import valueIllust4 from "../../images/illustrations/ourvalues-expressive.png"
import styled from "styled-components"

const Header = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export default class Schedule extends React.Component {
  state = {
    name: "",
    email: "",
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })

    this.setState({
      mailToString:
        "/schedule-demo?name=" + this.state.name + "&email=" + this.state.email,
    })
  }

  render() {
    return (
      <div>
        <div className="relative overflow-hidden">
          <div className="mx-auto p-6 md:pt-6 sm:p-8">
          <Header>
          <div>

            <h2
              style={{
                fontWeight: 800,
              fontSize: 28,
              }}
              className="tracking-tight font-bold sm:leading-none text-2xl sm:text-lg md:text-4xl mb-2"
            >
              Our Values 
            </h2>
          </div>
        </Header>
         <div className="md:flex p-4 justify-center">
          <div className="ml-4 mr-4 flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 ">
            <img
             style={{ width: 200}}
                    className="w-auto h-auto"
            src={valueIllust1}
            alt="Focus"
            />
          </div>
          <div className="ml-4 mr-4 flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 ">
            <img
             style={{ width: 200}}
                    className="w-auto h-auto"
            src={valueIllust2}
            alt="Accountable"
            />
          </div>
          <div className="ml-4 mr-4 flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 ">
            <img
             style={{ width: 200}}
                    className="w-auto h-auto"
            src={valueIllust3}
            alt="Curiosity"
            />
          </div>
          <div className="ml-4 mr-4 flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 ">
            <img
             style={{ width: 200}}
                    className="w-auto h-auto"
            src={valueIllust4}
            alt="Communication"
            />
          </div>
        </div>
            <div className="md:flex p-4 justify-center">
              <div className="ml-4 mr-4 flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 ">
               <div className="" >
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 18,
                      }}
                      className="mb-2 tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Awareness
                    </h2>
                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mb-4 para"
                    >
                  Differentiate on most important and least important.
                    </p>

                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 18,
                      }}
                      className="mb-2 tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Result-Oriented 
                    </h2>
                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mb-4 para"
                    >
                  Goal set, planned, and executed; always measure.
                    </p>
                </div>
              </div>
              <div className="ml-4 mr-4 flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 ">
               <div className="" >
               <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 18,
                      }}
                      className="mb-2 tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Grit 
                    </h2>
                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mb-4 para"
                    >
                  Your determination is your first stone of foundation.
                    </p>

                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 18,
                      }}
                      className="mb-2 tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Commitment 
                    </h2>
                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mb-4 para"
                    >
                  Whatever comes, you are bound to your goal.
                    </p>
                </div>
              </div>
              <div className="ml-4 mr-4 flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 ">
               <div className="" >
                   <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 18,
                      }}
                      className="mb-2 tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Fun
                    </h2>
                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mb-4 para"
                    >
                  Best solutions come through curious questions.
                    </p>

                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 18,
                      }}
                      className="mb-2 tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Learn 
                    </h2>
                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mb-4 para"
                    >
                  Every exploration is a learning curve
                    </p>
                </div>
              </div>
              <div className="ml-4 mr-4 flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 ">
               <div className="" >
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 18,
                      }}
                      className="mb-2 tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Courage 
                    </h2>
                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mb-4 para"
                    >
                  Dare to speak up and often express, hear your own voice.
                    </p>

                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 18,
                      }}
                      className="mb-2 tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Empathy 
                    </h2>
                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mb-4 para"
                    >
                  Listening and understanding bring clarity and relationship.
                    </p>
                </div>
              </div>

              
            </div>

          </div>
        </div>
      </div>
    )
  }
}

/** @jsx jsx */
import { jsx } from "theme-ui"
import dashboardIllust from "../../images/illustrations/about-threepeople.jpg"
import { Link } from "gatsby"

const CoachingBlock = ({ offset }) => (
  <div style={{ backgroundColor: "rgb(102, 51, 153)" }}>
    <div className="relative overflow-hidden">
      <div className="mx-auto p-6 md:pt-12 sm:p-8">
        <div className="md:flex p-4 justify-center">
          <div className="w-1/2">
            <h1 style={{ marginBottom: 0 }} className="mt-2 text-white">
              Become a BeingZone Coach
            </h1>
            <p style={{ marginTop: "-4px" }} className="text-white para">
              lets transform together
            </p>

            <p className="mt-4 text-white para">
              We have taken a big mission. It takes inspired and experienced coaches, industry
              experts, trained professionals and behavior scientist to build a conscious society.
            </p>
            <h1 className="mt-4 text-white uppercase">
              Join the community
            </h1>
            <p className="mt-4 text-white para">
              Together we can make a difference in the lives of individuals.
            </p>
            <div className="mt-8">
          <Link
            target="_blank"
            to={"https://form.typeform.com/to/zYxMCRXn"}
            className="cursor-pointer shadow bg-zone-cta focus:shadow-outline focus:outline-none  font-bold py-3 px-6 rounded"
          >
            Apply Now
          </Link>
        </div>
          </div>
          <div
            className="self-center hidden md:block w-1/2"
          >
            <img
              style={{ marginRight: "-168px" }}
              className="w-auto h-auto"
              src={dashboardIllust}
              alt="illustration"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default CoachingBlock

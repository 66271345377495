import React, { useEffect } from "react"
import Layout from "../components/layout"
import HeroAbout from "../components/about/hero-about"
import Quote from "../components/about/quote"
import Bwhole from "../components/about/bzintro-whole"
import Ourvalue from "../components/about/ourvalue"
import CoachingBlock from "../components/about/coaching-block"

const About = () => {
  useEffect(() => {
    console.log("🔥 Initializing Firebase Analytics & Performance 🔥")
    // firebase.analytics()
    // firebase.performance() 
  }, [])

  return (
    <Layout>
      <HeroAbout />
      <Quote />
      <Bwhole />
      <Ourvalue />
      <CoachingBlock/>

    </Layout>
  )
}

export default About
